<template>
  <div class="skin">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="skin-back"
      @click="back()"
    />
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/1413a69cacb7ba1c1bc1348e58696642.png"
      alt=""
      class="skin-but"
    />
    <div class="skin-box">
      <div class="skin-box-type flex">
        <div
          v-for="(item, index) in typeList"
          :key="index"
          class=""
          :class="typeIndex == index ? 'skin-box-type-li1' : 'skin-box-type-li'"
          @click="handleSelect(index)"
        >
          {{ item.name }}
        </div>
        <!-- <el-menu
          :default-active="typeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="transparent"
          text-color="#fff"
        >
          <el-menu-item
            :index="index"
            v-for="(item, index) in typeList"
            :key="index"
            >{{ item.name }}</el-menu-item
          >
        </el-menu> -->
      </div>
      <div class="skin-commodity flex">
        <div
          v-for="(item, index) in commodityList"
          :key="index"
          @click="commodity(item)"
        >
          <div
            v-if="item.isSell == 100 || item.isWantBuy == 100"
            class="skin-commodity-li"
          >
            <div class="skin-commodity-li-text">{{ item.name }}</div>
            <div class="skin-commodity-li-imgbac">
              <img :src="item.img" alt="" class="skin-commodity-li-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeIndex: 0,
      typeList: [],
      commodityList: [],
    };
  },
  mounted() {
    this.typeList = JSON.parse(this.$route.query.data).children;
    this.commodityList = this.typeList[this.typeIndex].children;
  },
  methods: {
    handleSelect(key) {
      if (this.typeIndex == key) return;
      this.typeIndex = key;
      this.commodityList = this.typeList[this.typeIndex].children;
    },
    commodity(item) {
      if (item.isSell == 100 && item.isWantBuy == 100) {
        this.$router.push({
          path: "/marketplace/property/bazaar",
          query: { categoryId: item.categoryId, type: this.type },
        });
      } else {
        this.$router.push({
          path: "/marketplace/skin/bazaar",
          query: { categoryId: item.categoryId, type: this.type },
        });
      }
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.skin::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.skin {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/marketplace/consignmentsales.png")
    no-repeat;
  background-size: 100% 100%;
  // position: relative;
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: 50px 0;
  padding-bottom: 10px;

  &-but {
    position: absolute;
    right: 20px;
    bottom: 0;
    width: 203px;
    height: 413px;
  }
  &-back:hover {
    cursor: pointer;
  }
  &-back {
    position: absolute;
    top: 163px;
    left: 90px;
    width: 72px;
    height: 72px;
  }
  :deep(.el-menu.el-menu--horizontal) {
    border-bottom: solid 0.005208rem #616161 !important;
  }
  :deep(.el-menu--horizontal) {
    background: transparent !important;
  }
  :deep(.el-menu--horizontal > .el-menu-item.is-active) {
    color: #fff !important;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 0.010417rem solid #daff00;
  }
  :deep(.el-menu-item) {
    padding: 0 30px;
  }
  :deep(.el-menu-item):hover {
    background-color: transparent !important;
  }
  &-box {
    width: 1068px;
    height: 759px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/A10_pop_img_004.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 34px 49px;

    &-type::-webkit-scrollbar {
      width: 11px; /* 设置滚动条的宽度 */
      height: 10px;
    }
    // &-type::-webkit-scrollbar-track {
    //   background: transparent; /* 设置滚动条的宽度 */
    // }
    &-type::-webkit-scrollbar-thumb {
      background: #616161; /* 设置滑块的背景颜色 */
      max-width: 140px;
      border-radius: 8px;
    }
    &-type {
      margin: auto;
      width: 706px;
      height: 83.45px;
      border-radius: 9.7px;
      background: #685c84;
      box-shadow: 0 3.88px 3.88px 0 #00000040 inset;
      overflow: auto;
      padding-left: 0;
      align-items: center;
      padding: 0 20px;
      &-li:hover {
        cursor: pointer;
      }
      &-li1:hover {
        cursor: pointer;
      }
      &-li1 {
        // width: 140px;
        padding: 0 20px;
        height: 52px;
        line-height: 52px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10493.png")
          no-repeat;
        background-size: 100% 100%;
        color: #2f2f2f;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 22px;
        font-weight: bold;
        margin-left: 16px;
        white-space: nowrap;
      }
      &-li {
        // width: 140px;
        padding: 0 20px;
        height: 52px;
        line-height: 52px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10494.png")
          no-repeat;
        background-size: 100% 100%;
        color: #2f2f2f;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 22px;
        font-weight: bold;
        margin-left: 16px;
        white-space: nowrap;
      }
    }
  }
  &-commodity::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &-commodity {
    flex-wrap: wrap;
    margin-top: 40px;
    height: 540px;
    overflow: auto;
    &-li:nth-child(4n) {
      margin-right: 0;
    }
    &-li:hover {
      cursor: pointer;
    }

    &-li {
      width: 220px;
      height: 303px;
      background: url("https://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/marketplace/Group10428.png")
        no-repeat;
      background-size: 100% 100%;
      // margin-bottom: 34.41px;
      margin-right: 17.14px;
      padding-top: 35px;
      &-imgbac {
        width: 100%;
        height: 185.45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-img {
        width: 161px;
        height: 161px;
      }
      &-text {
        color: #ffffff;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
}
</style>